import { Navigate } from "react-router-dom";

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password";
import ResetNewPassword from "layouts/authentication/confirmResetPassword";
import PrivateRoute from "layouts/authentication/PrivateRoute";

// Soft UI Dashboard React icons
import Shop from "reusableComponents/Icons/Shop";
import Office from "reusableComponents/Icons/Office";
import Document from "reusableComponents/Icons/Document";
import SpaceShip from "reusableComponents/Icons/SpaceShip";
import CustomerSupport from "reusableComponents/Icons/CustomerSupport";
import Departments from "./mainComponents/departments/Departments";
import Librarians from "./mainComponents/librarians/Librarians";
import publications from "./layouts/publications";
import { HiMiniUsers } from "react-icons/hi2";
import ResearcherManagement from "./mainComponents/researcherManagement/ResearcherManagement";
import confirmation from "./layouts/authentication/confirmation";
import Report from "./mainComponents/report/Report";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['Admin', 'Librarian', 'Researcher']} component={Dashboard} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Departments List",
    key: "departments-list",
    route: "/departments-list",
    icon: <Office size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['Admin']} component={Departments} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Librarian Management",
    key: "librarian-management",
    route: "librarian-management",
    icon: <Office size="12px" />,
    component: <Librarians />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['Admin', 'Librarian', 'Researcher']} component={Profile} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <PrivateRoute publicRoute={true} component={SignIn} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: <PrivateRoute publicRoute={true} component={SignUp} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password Form",
    key: "reset-password-form",
    route: "/authentication/reset-password",
    icon: <SpaceShip size="12px" />,
    component: <PrivateRoute publicRoute={true} component={ResetPassword} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Confirm Reset Password",
    key: "confirm-reset-password",
    route: "/authentication/confirm-reset-password/:token",
    icon: <SpaceShip size="12px" />,
    component: <PrivateRoute publicRoute={true} component={ResetNewPassword} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Researcher Management",
    key: "researcher-management",
    route: "/researcher-management",
    icon: <HiMiniUsers size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['Librarian']} component={ResearcherManagement} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Documents",
    key: "documents",
    route: "/documents",
    component: <PrivateRoute allowedRoles={['Admin', 'Librarian', 'Researcher']} component={publications} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Confirmation",
    key: "confirmation",
    route: "/authentication/confirmation",
    component: <PrivateRoute publicRoute={true} component={confirmation} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    route: "/report",
    component: <PrivateRoute allowedRoles={['Admin','Librarian']} component={Report} />,
    noCollapse: true,
  }
];

export default routes;
