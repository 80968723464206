
// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";

// Soft UI Dashboard React icons
import Shop from "reusableComponents/Icons/Shop";
import Office from "reusableComponents/Icons/Office";
import Departments from "./mainComponents/departments/Departments";
import Librarians from "./mainComponents/librarians/Librarians";
import { MdGroup } from 'react-icons/md';
import { HiMiniUsers } from "react-icons/hi2";
import ResearcherManagement from "./mainComponents/researcherManagement/ResearcherManagement";
import Document from "./reusableComponents/Icons/Document";
import Report from "./mainComponents/report/Report";
import { BiSolidReport } from "react-icons/bi";

const sideBarItems = {
    'Admin':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Departments list",
                key: "departments-list",
                route: "/departments-list",
                icon: <Office size="12px"/>,
                component: <Departments/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Librarian Management",
                key: "librarian-management",
                route: "librarian-management",
                icon:<MdGroup style={{fontSize: '14px'}} />,
                component: <Librarians/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Report",
                key: "report",
                route: "/report",
                icon: <BiSolidReport size="12px"/>,
                component: <Report/>,
                noCollapse: true,
            }
        ],
    'Librarian':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Researcher Management",
                key: "researcher-management",
                route: "/researcher-management",
                icon: <HiMiniUsers size="12px"/>,
                component: <ResearcherManagement/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Report",
                key: "report",
                route: "/report",
                icon: <BiSolidReport size="12px"/>,
                component: <Report/>,
                noCollapse: true,
            }
        ],
    'Researcher':
        [
            {
                type: "collapse",
                name: "Dashboard",
                key: "dashboard",
                route: "/dashboard",
                icon: <Shop size="12px"/>,
                component: <Dashboard/>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Digital Database",
                key: "documents",
                route: "/documents",
                icon: <Document size="12px" />,
                noCollapse: true,
            }
        ]
};

export default sideBarItems;
