import axios from 'axios';
const baseURL = process.env.REACT_APP_API_GATEWAY;

const axiosClient = axios.create({
    baseURL: `${baseURL}/api/`,
});

export const login = (email, password) => {

    return axiosClient.post('login', { email, password });
};

export const logout = () => {

    return axiosClient.post('logout');
};

export const register = (formData) => {
    const { name, email, password, department } = formData;

        return axiosClient.post('register', {
            name,
            email,
            password,
            department_id: department
        });
};
export const addLibrarian = async (formData) => {
    const { name, email, password, department, address, phone } = formData;

    return axiosClient.post('add_librarian', {
        name,
        email,
        password,
        department_id: department,
        address,
        phone
    });
};

const getToken = () => localStorage.getItem('token');

axiosClient.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {

        if (error.response) {

            if (error.response.status === 401 && error.config.url != "login") {

                localStorage.removeItem('token');
                window.location.href = "/authentication/sign-in";
            } else if (error.response.status === 403) {

                window.location.href = "/dashboard";
            }
        } else {
            console.error('An error occurred:', error);
        }
        return Promise.reject(error);
    }
);

export const getStatistics = () => {
    return  axiosClient.get(`statistics`)
};


export const getResearchersActivity = (params) => {
    return axiosClient.get('researchers-activity', { params });
};

export const activateResearchers = (ids, data) => {
    return  axiosClient.put(`activate-users/${ids}`, data)
};

export const getResearchersList = (params) => {
    return axiosClient.get('researchers' + params );
};

export const getUsersList = (params) => {
    return axiosClient.get('users', { params });
};

export const getDepartementsList = (params = '') => {
    return axiosClient.get(`departments${params}`);
};
export const getAnonymousUsersList = (params) => {
    return axiosClient.get('anonymous-users', { params });
};
export const getLibrariansList = (params) => {
    return axiosClient.get('librarians' + params );
};
export const getUserProfile = () => {
    return axiosClient.get('profile');
};
export const updateProfile = (id, updatedData) => {
    return axiosClient.put(`/users/${id}`, updatedData);
};
export const getUniversities = () => {
  return axiosClient.get('universities')
};

export const addDepartment = (data) => {
    return axiosClient.post('departments', data)
};
export const updateDepartment = (id, updatedData) => {
    return axiosClient.put(`/departments/${id}`, updatedData);
};
export const getArchives = (params) => {

    return axiosClient.get('digitizations' + params);
};

export const getArchivesByPeriod = (params) => {

    return axiosClient.get('digitizations-by-period' + params);
};

export const getArchivesById = (id) => {
    return axiosClient.get(`digitizations/${id}`);
};

export const getSubjects = () => {
    return axiosClient.get('subjects')
};

export const addSubject = (data) => {
    return axiosClient.post('subjects', data)
};

export const digitize = (data) => {
    return axiosClient.post('digitizations', data)
};

export const deleteArticle = (article_id) => {
    return axiosClient.delete(`/digitizations/${article_id}`);
};


export const forgotPassword = (email) => {
    return axiosClient.post('password-reset-request', { email });
    
};



export const UpdatePassword = (token, new_password, confirm_password) => {
    return axiosClient.post('password-reset-confirm', { 
      token, 
      new_password, 
      confirm_password 
})};

export const getDigitizationsCount = () => {
    return axiosClient.get('digitizations-count');
};
export const checkDuplicatedArticles = (params) => {
    return axiosClient.get('check-duplicated-articles', params);
};