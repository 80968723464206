/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../Navbars/DashboardNavbar";
import Footer from "../../Footer";
import Table from "reusableComponents/Tables/Table/index";
import SuiButton from "../../../components/SoftButton";
import { IoIosAdd } from "react-icons/io";
import PropTypes from "prop-types";
import Checkbox from '@mui/material/Checkbox';
import { MenuItem, Select } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Filter from "../../Filters/Filter";


const TableCard = ({
                       rows,
                       title,
                       showAddButton,
                       ButtonName,
                       onButtonClick,
                       loadingData,
                       showIcon = true,
                       text,
                       showCheckBox,
                       onCheckChange,
                       optionChecked,
                       collapsed,
                       collapsedRows,
                       count,
                       size,
                       page,
                       handlePageChange,
                       handleRowsPerPageChange,
                       filters,
                       filterData,
                       handleFilterChange,
                       displayFooter = false,
                       footerRow,
                       selector
                   }) =>
{

    const handlePaginationSizeChange = (event) => {
        let newSize = parseInt(event.target.value, 10);
        handleRowsPerPageChange(newSize);
    };

    const handlePaginationPageChange = (event, newPage) => {
        const numberOfPages = Math.ceil((count ?? rows.length) / size);
        let page = newPage <= numberOfPages ? newPage : 1;
        handlePageChange(page);
    };


  return (
    <DashboardLayout>
      <DashboardNavbar />
        {filters ? (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1rem', gap: '1rem', paddingLeft:"1rem", marginTop:"1rem"}}>
                {filters.map((filter, index) => (
                    <Filter
                        key={index}
                        type={filter.type}
                        label={filter.label}
                        value={filterData[filter.key]}
                        filterData={filter.options || []}
                        onChange={(e) => handleFilterChange(filter.key, filter.type === 'date' ? e : e.target.value)}
                    />
                ))}
            </div>
        ) : null }

      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">{title}</SoftTypography>
                <SoftBox display="flex" alignItems="center" gap={2}>
                    {showCheckBox && (
                        <SoftBox display="flex" alignItems="center">
                            <Checkbox checked={optionChecked} onChange={onCheckChange} />
                            <SoftTypography sx={{ fontSize: "12px" }}>{text}</SoftTypography>
                        </SoftBox>
                    )}
                    {showAddButton && (
                        <SuiButton variant="gradient" color="primary" size="medium" onClick={onButtonClick}>
                            {showIcon && <IoIosAdd />}
                            {ButtonName}
                        </SuiButton>
                    )}
                </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
                {rows.length > 0 ?
                        <Table rows={rows} collapsed={collapsed} collapsedRows={collapsedRows} selector={selector} displayFooter={displayFooter} footerRow={footerRow}/> : loadingData ? <p style={{textAlign: "center", padding: "50px"}}> Loading... </p> :
                        <p style={{textAlign: "center", padding: "50px"}}> No results found!</p>}
            </SoftBox>
          </Card>
        </SoftBox>

          <SoftBox key={"pagination-card"}>
              <SoftBox>
                  <SoftBox style={{display: "flex"}}>
                      <SoftBox style={{marginLeft: "auto"}}>
                          <Select
                              id="rowsPerPage"
                              fullWidth
                              value={size}
                              onChange={handlePaginationSizeChange}
                              style={{
                                  width: "100%",
                                  padding: "10px 0px",
                                  border: "none",
                                  fontSize: "14px",
                                  boxSizing: "border-box",
                                  outline: "none",
                                  fontFamily: "Arial, Roboto",
                              }}
                              inputProps={{"aria-label": "Without label"}}
                          >
                              <MenuItem key={"5"} value="5">5</MenuItem>
                              <MenuItem key={"10"} value="10" selected={true}>10</MenuItem>
                              <MenuItem key={"20"} value="20">20</MenuItem>
                          </Select>
                      </SoftBox>
                      <SoftBox>
                          <Pagination
                              size="sm"
                              color="primary"
                              count={Math.ceil(count / size)}
                              page={page}
                              onChange={handlePaginationPageChange}
                              showFirstButton
                              showLastButton
                              style={{marginLeft: "auto"}}
                          />
                      </SoftBox>
                  </SoftBox>
              </SoftBox>
          </SoftBox>

      </SoftBox>
      {/*<Footer />*/}
    </DashboardLayout>
  );
}

// Typechecking props for the Table
TableCard.propTypes = {
    title: PropTypes.string,
    ButtonName: PropTypes.string,
    text: PropTypes.string,
    selector: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object),
    collapsedRows: PropTypes.arrayOf(PropTypes.object),
    showAddButton: PropTypes.bool,
    showIcon: PropTypes.bool,
    showCheckBox: PropTypes.bool,
    loadingData: PropTypes.bool,
    optionChecked: PropTypes.bool,
    onButtonClick: PropTypes.func,
    onCheckChange: PropTypes.func,
    count: PropTypes.number,
    size: PropTypes.number,
    page: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    reportCount: PropTypes.number,
    displayFooter: PropTypes.bool,
    collapsed: PropTypes.bool,
    footerRow: PropTypes.object,
    filters: PropTypes.arrayOf(PropTypes.object),
    handleFilterChange: PropTypes.func,
    filterData: PropTypes.object
};
export default TableCard;
