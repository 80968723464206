import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import { getLibrariansList } from "../../axios-client";
import AddLibrarianDialog from "./AddLibrarianDialog";
import { IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import Tooltip from "@mui/material/Tooltip";
import EditLibrarianDialog from "./EditLibrarianDialog";
import {formatDataToUrlQueryParams} from "../../utlis/generalUtils";
import ResetPasswordDialog from "./ResetPasswordDialog";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";

const Librarians = (props) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddLibrarianDialog, setOpenAddLibrarianDialog] = useState(false);
    const [openEditLibrarianDialog, setOpenEditLibrarianDialog] = useState(false);
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
    const [selectedLibrarian, setSelectedLibrarian] = useState(null);
    const [librariansCount, setLibrariansCount] = useState(0);

    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
    });

    useEffect(() => {
        if (filterData) {
            fetchLibrarians(filterData);
        }
    }, [filterData]);


    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };

    const fetchLibrarians = (filterData = {}) => {
        setLoading(true);
        const queryParams = formatDataToUrlQueryParams(filterData);
        getLibrariansList(`?${queryParams}`)
            .then(res => {
                const formattedRows = res.data.users.map(librarian => ({
                    "Name": (
                        <AvatarComponent name={librarian.name} image={librarian.profile_picture ?? librarian.name}
                                         bgColor={"secondary"}
                                         variant={"rounded"}/>),
                    "Email": librarian.email,
                    "Phone": librarian.phone,
                    "Department": librarian.department,
                    "Action":  (
                        <>
                            <Tooltip title="Edit librarian's information">
                            <IconButton
                                onClick={() => openEditDialog(librarian)}
                                style={{ cursor: 'pointer' }}
                                size="small"
                            >
                                <Edit />
                            </IconButton>
                            </Tooltip>

                            <Tooltip title="Reset password">
                                <IconButton
                                    onClick={() => handleOpenResetPasswordDialog(librarian)}
                                    style={{ cursor: 'pointer' }}
                                    size="small"
                                >
                                    <KeyIcon/>
                                </IconButton>
                            </Tooltip>
                        </>
                    ),
                }));
                setLibrariansCount(res.data.count);
                setRows(formattedRows);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching librarians:', error);
                setLoading(false);
            });
    };


    const handleOpenDialog = () => {
        setOpenAddLibrarianDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddLibrarianDialog(false);
    };
    const openEditDialog = (librarian) => {
        setSelectedLibrarian(librarian);
        setOpenEditLibrarianDialog(true);
    };

    const closeEditDialog = () => {
        setOpenEditLibrarianDialog(false);
        setSelectedLibrarian(null);
    };

    const handleOpenResetPasswordDialog = (librarian) => {
        setSelectedLibrarian(librarian);
        setOpenResetPasswordDialog(true);
    };

    const handleCloseResetPasswordDialog = () => {
        setOpenResetPasswordDialog(false);
        setSelectedLibrarian(null);
    };


    const refreshLibrariansList = () => {
        handleCloseDialog();
        fetchLibrarians(filterData);
    };

    return (
        <div>
            <Tables rows={rows}
                    title={"Librarians"}
                    showAddButton={true}
                    ButtonName={"Add Librarian"}
                    onButtonClick={handleOpenDialog}
                    loadingData={loading}
                    size={filterData.size}
                    page={filterData.page}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    count={librariansCount}
            />
            {openAddLibrarianDialog && (
                <AddLibrarianDialog
                    open={openAddLibrarianDialog}
                    onClose={handleCloseDialog}
                    onLibrarianAdded={refreshLibrariansList}
                />
            )}
            {openEditLibrarianDialog && (
                <EditLibrarianDialog
                    open={openEditLibrarianDialog}
                    onClose={closeEditDialog}
                    onLibrarianAdded={fetchLibrarians}
                    librarianData={selectedLibrarian}
                />
            )}
            {openResetPasswordDialog && (
                <ResetPasswordDialog
                    open={openResetPasswordDialog}
                    onClose={handleCloseResetPasswordDialog}
                    onLibrarianAdded={fetchLibrarians}
                    librarianData={selectedLibrarian}
                />
            )}
        </div>
    );
};

export default Librarians;
