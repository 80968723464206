import { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import ProfileInfoCard from "reusableComponents/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { updateProfile, getUserProfile } from "../../axios-client";
import Footer from "../../reusableComponents/Footer";
import DashboardNavbar from "../../reusableComponents/Navbars/DashboardNavbar";

function Profile() {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        address: "",
        department: "",
        profile_picture:""
    });
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        // if (!formData.department) newErrors.department = "Department is required";
        const phonePattern = /^\+?[1-9]\d{1,14}$/;
        if (!formData.phone || !phonePattern.test(formData.phone)) newErrors.phone = "Invalid phone number";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {

            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('address', formData.address);
            formDataToSend.append('department', formData.department);


            if (formData.profile_picture) {
                formDataToSend.append('profile_picture', formData.profile_picture);
            }

            try {
                const response = await updateProfile(formData.id, formDataToSend);
                setSnackbar({ open: true, message: "Profile updated successfully", severity: "success" });
                window.location.reload();
            } catch (error) {
                setSnackbar({ open: true, message: "Error updating profile", severity: "error" });
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: "", severity: "" });
    };

    useEffect(() => {
        async function fetchUserProfile() {
            try {
                const response = await getUserProfile();
                const { name, phone, email, address, department, profile_picture } = response.data;
                setFormData({
                    name: name,
                    phone: phone,
                    email: email,
                    address: address,
                    department: department,
                    profile_picture: profile_picture,
                    id: response.data.id,
                });
            } catch (error) {
                console.error("Failed to fetch user profile", error);
            }
        }

        fetchUserProfile();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Header info={formData}
                    setInfo={setFormData}/>
            <SoftBox mt={5} mb={3}>
                        <ProfileInfoCard
                            title="Profile Information"
                            info={formData}
                            setInfo={setFormData}
                            errors={errors}
                            handleSubmit={handleSubmit}
                            action={{ route: "", tooltip: "Edit Profile" }}

                        />
            </SoftBox>

            <Footer />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
}

export default Profile;
