/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// DefaultLineChart configurations


// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import configs from "./configs/LineChartConfig";

function GradienttLineChart({ title, height, chart }) {
    const chartDatasets = chart.datasets
        ? chart.datasets.map((dataset) => ({
            ...dataset,
            tension: 0.4,
            borderWidth: 3,
            pointRadius: 2,
            pointBackgroundColor: colors[dataset.color]
                ? colors[dataset.color || "dark"].main
                : colors.dark.main,
            borderColor: colors[dataset.color]
                ? colors[dataset.color || "dark"].main
                : colors.dark.main,
            maxBarThickness: 6,
        }))
        : [];

    const { data, options } = configs(chart.labels || [], chartDatasets);

    const renderChart = (
        <SoftBox p={2}>
            {useMemo(
                () => (
                    <SoftBox height={height}>
                        <Line data={data} options={options} />
                    </SoftBox>
                ),
                [chart, height]
            )}
        </SoftBox>
    );

    return title ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultLineChart
GradienttLineChart.defaultProps = {
    title: "",
    height: "19.125rem",
};

// Typechecking props for the DefaultLineChart
GradienttLineChart.propTypes = {
    title: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default GradienttLineChart;
