import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";
import {getDepartementsList} from "../../../../axios-client";

function ProfileInfoCard({ title, info, setInfo, errors, handleSubmit, action }) {
  const [isFocused, setIsFocused] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [isEditing, setEditing] = useState(false);

  const inputContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
    borderRadius: '8px',
    padding: '5px 10px',
    marginBottom: '10px',
    transition: 'border-color 0.3s ease',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px 0px',
    border: 'none',
    fontSize: '14px',
    boxSizing: 'border-box',
    outline: 'none',
    fontFamily: 'Arial, Roboto',
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const toggleEditMode = () => {
    setEditing(!isEditing);
  };

  useEffect(() => {
    async function loadDepartments() {
      try {
        const response = await getDepartementsList();
        setDepartments(response.data || []);
      } catch (error) {
        console.error("Failed to load departments", error);
        setDepartments([]);
      }
    }
    loadDepartments();
  }, []);

  const cancelChanges = () => {
    setEditing(false);
  }

  return (
      <Card sx={{ height: "100%" }}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SoftTypography>
          <SoftTypography variant="body2" color="secondary">
            <Tooltip title={action.tooltip} placement="top">
              <Icon onClick={toggleEditMode} style={{ cursor: 'pointer' }}>edit</Icon>
            </Tooltip>
          </SoftTypography>
        </SoftBox>
        <SoftBox p={2}>
          <SoftBox>
            {!isEditing ? (
                <div>
                  <SoftTypography variant="body2" color="text">Full Name: {info.name}</SoftTypography>
                  <SoftTypography variant="body2" color="text">Email: {info.email}</SoftTypography>
                  <SoftTypography variant="body2" color="text">Phone: {info.phone}</SoftTypography>
                  <SoftTypography variant="body2" color="text">Address: {info.address}</SoftTypography>
                  { info.department && <SoftTypography variant="body2" color="text">Department: {info.department}</SoftTypography> }
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                  <label htmlFor="name" className="label-style">Full Name</label>
                  <div style={inputContainerStyle}>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        style={inputStyle}
                        value={info.name}
                        onChange={handleInputChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />

                  </div>
                  {errors.name && (
                      <SoftTypography color="error" fontSize="small">
                        {errors.name}
                      </SoftTypography>
                  )}
                  <label htmlFor="email" className="label-style">Email</label>
                  <SoftTypography variant="body2"
                                  color="text">{info.email}</SoftTypography> {/* Email remains non-editable */}

                  <label htmlFor="phone" className="label-style">Phone</label>
                  <div style={inputContainerStyle}>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        style={inputStyle}
                        value={info.phone}
                        onChange={handleInputChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                  </div>
                  {errors.phone && (
                      <SoftTypography color="error" fontSize="small">
                        {errors.phone}
                      </SoftTypography>
                  )}
                  { info.department && <div> <label htmlFor="department" className="label-style">Department</label>

                    <SoftTypography variant="body2"
                    color="text">{info.department}</SoftTypography>
                  </div>
                  }

                  <label htmlFor="address" className="label-style">Address</label>
                  <div style={inputContainerStyle}>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        style={inputStyle}
                        value={info.address}
                        onChange={handleInputChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                  </div>

                  <SoftButton type="submit" variant="gradient" color="primary" sx={{float:"right"}} >
                    Save Changes
                  </SoftButton>
                  <SoftButton variant="gradient" color="secondary" sx={{float:"right", marginRight:"0.5rem"}} onClick={cancelChanges} >
                    Cancel
                  </SoftButton>
                </form>
            )}
          </SoftBox>
        </SoftBox>
      </Card>
  );
}

ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
  setInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileInfoCard;
