import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Box from "@mui/material/Box"; // Assuming you're using Material UI for layout
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useRef } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ researchersCount, librariansCount }) => {
    const chartRef = useRef(null);

    // Function to create a gradient color
    const createGradient = (ctx, chartArea, colorStart, colorEnd) => {
        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, colorStart);
        gradient.addColorStop(1, colorEnd);
        return gradient;
    };

    const data = {
        labels: ["Researchers", "Librarians"],
        datasets: [
            {
                data: [researchersCount, librariansCount],
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return null;
                    }

                    // Create gradients for each segment
                    return [
                        createGradient(ctx, chartArea, "#f00084", "#833ab4"),
                        createGradient(ctx, chartArea, "#01708f", "#63b5e8")
                    ];
                },
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        cutout: "70%",
        plugins: {
            legend: {
                display: false, // Custom legend below
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    },
                },
            },
        },
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2.813rem",
                backgroundColor: "white",
                borderRadius: "15px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Box sx={{ position: "relative", width: "160px", height: "160px"}}>
                <Doughnut ref={chartRef} data={data} options={options} />

                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h5" fontWeight="bold">
                        {researchersCount + librariansCount}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        Total
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ paddingLeft: "0.5rem" }}>
                {data.labels.map((label, index) => (
                    <Box key={index} sx={{ display: "flex", alignItems: "center", marginBottom: "10px", marginRight: "6rem" }}>
                        <Box
                            sx={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: index === 0 ? "#f00084" : "#021c4d", // Use static colors for the legend
                                borderRadius: "50%",
                            }}
                        />
                        <Typography variant="body2" fontWeight="bold" sx={{ marginRight: "0.5rem" }}>
                            {label}
                        </Typography>
                        <Typography variant="body2" sx={{ marginLeft: "auto" }}>
                            {data.datasets[0].data[index]}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

DonutChart.propTypes = {
    researchersCount: PropTypes.number,
    librariansCount: PropTypes.number
}
export default DonutChart;
