import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import { MdLocalPhone, MdPerson, MdHome, MdEmail, MdLock } from "react-icons/md";
import { LuBuilding } from "react-icons/lu";
import SoftButton from "../../components/SoftButton";
import { getDepartementsList, addLibrarian } from "../../axios-client";
import { IoClose } from "react-icons/io5";
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";

const AddLibrarianDialog = ({ open, onClose, onLibrarianAdded }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        department: '',
        password: "",
        address: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [departments, setDepartments] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        async function loadDepartments() {
            try {
                const response = await getDepartementsList();
                setDepartments(response.data || []);
            } catch (error) {
                console.error("Failed to load departments", error);
                setDepartments([]);
            }
        }
        loadDepartments();
    }, []);
    const handleDepartmentChange = (e) => {
        setFormData({ ...formData, department: e.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.phone) formErrors.phone = 'Phone is required';
        if (!formData.department) formErrors.department = 'Department is required';
        if (!formData.password) formErrors.password = "Password is required";

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailPattern.test(formData.email)) {
            formErrors.email = "Please enter a valid email address";
        }
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordPattern.test(formData.password)) {
            formErrors.password = "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character";
        }
        return formErrors;
    };

    const handleSubmit = async () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            try {
                await addLibrarian(formData);
                setSnackbar({ open: true, message: 'Librarian added successfully', severity: 'success' });
                setTimeout(() => {
                    onLibrarianAdded();
                }, 1000);
            } catch (error) {
                setSnackbar({ open: true, message: 'Failed to add librarian', severity: 'error' });
            }
        } else {
            setErrors(formErrors);
            setSnackbar({ open: true, message: 'Please fill out all required fields', severity: 'error' });
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };
    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle
                id="form-dialog-title"
                sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
                Add New Librarian
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        size: 10
                    }}
                />
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <label htmlFor="name" className="label-style">Name</label>
                <div style={inputContainerStyle}>
                    <MdPerson className="icon-style"/>
                    <input type="text" id="name" name="name" style={inputStyle}
                           value={formData.name}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}/>
                </div>
                {errors.name && <p style={{color: 'red', fontSize: "12px"}}>{errors.name}</p>}

                <label htmlFor="email" className="label-style">Email</label>
                <div style={inputContainerStyle}>
                    <MdEmail className="icon-style"/>
                    <input type="text" id="email" name="email" style={inputStyle}
                           value={formData.email}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}/>
                </div>
                {errors.email && <p style={{color: 'red', fontSize: "12px"}}>{errors.email}</p>}

                <label htmlFor="password" className="label-style">Password</label>
                <div style={{ ...inputContainerStyle, position: 'relative' }}>
                    <MdLock className="icon-style"/>
                    <input type={showPassword ? "text" : "password"}
                           id="password" name="password" style={inputStyle}
                           value={formData.password}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}/>
                    <IconButton
                        onClick={togglePasswordVisibility}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            padding: "5px"
                        }}
                        aria-label="toggle password visibility"
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                </div>
                {errors.password && <p style={{color: 'red', fontSize: "12px"}}>{errors.password}</p>}

                <label htmlFor="phone" className="label-style">Phone</label>
                <div style={inputContainerStyle}>
                    <MdLocalPhone className="icon-style"/>
                    <input type="text" id="phone" name="phone" style={inputStyle}
                           value={formData.phone}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}/>
                </div>
                {errors.phone && <p style={{color: 'red', fontSize: "12px"}}>{errors.phone}</p>}

                <label htmlFor="department" className="label-style">Department</label>

                <SoftBox mb={2} style={{ position: 'relative' }}>
                    <LuBuilding className="icon-style" style={{
                        position: 'absolute',
                        left: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                        fontSize: '20px',
                        color: '#666'
                    }} />

                    <select
                        id="department"
                        name="department"
                        value={formData.department}
                        onChange={handleDepartmentChange}
                        style={{
                            width: "100%",
                            padding: "10px 10px 10px 40px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            background: "transparent",
                        }}
                    >
                        <option disabled={true} value="">
                            Select a department
                        </option>
                        {departments?.map((department) => (
                            <option key={department.id} value={department.id}>
                                {department.name}
                            </option>
                        ))}
                    </select>
                    {errors.department && (
                        <SoftTypography color="error" fontSize="small">
                            {errors.department}
                        </SoftTypography>
                    )}
                </SoftBox>


                <label htmlFor="address" className="label-style">Address</label>
                <div style={inputContainerStyle}>
                    <MdHome className="icon-style"/>
                    <input type="text" id="address" name="address" style={inputStyle}
                           value={formData.address}
                           onChange={handleChange}
                           onFocus={() => setIsFocused(true)}
                           onBlur={() => setIsFocused(false)}/>
                </div>
                {errors.address && <p style={{color: 'red', fontSize: "12px"}}>{errors.address}</p>}

            </DialogContent>

            <DialogActions>
                <SoftButton variant="outlined" color="dark" onClick={handleCancel}>
                    Cancel
                </SoftButton>
                <SoftButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Save
                </SoftButton>
            </DialogActions>

            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

AddLibrarianDialog.propTypes = {

    open: PropTypes.bool,
    onClose: PropTypes.func,
    onLibrarianAdded: PropTypes.func

}

export default AddLibrarianDialog;
