import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from "@mui/material/CardMedia";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { CiBookmark } from "react-icons/ci";
import { PiMagnifyingGlassPlusFill } from "react-icons/pi";
import { TbTextScan2 } from "react-icons/tb";
import defaultMiniature from "assets/images/curved-images/digitization.jpg";
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { MdDelete } from "react-icons/md"
import DeleteArticleDialog from "../../../layouts/publications/DeleteArticleDialog";
import {useSoftUIController} from "../../../context";

import Highlighter from "react-highlight-words";
import {downloadDocFile, downloadFileFromUrl} from "../../../utlis/generalUtils";

const CardItem = ({
                      title,
                      image,
                      author,
                      publishedAt,
                      summary,
                      createdAt,
                      status,
                      handleClickDownloadDigitizedDocument,
                      handleOpenPreview,
                      downloadBookmarkingFile,
                      subjects,
                      refreshArticles,
                      setNotification,
                      articleId,
                      searchText
}) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [controller, dispatch] = useSoftUIController();
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <Card sx={{ display: 'flex', flexDirection: 'row', border: '1px', margin: '5px', borderRadius: '0rem'}}>
            <SoftBox>
                <CardMedia
                    component="img"
                    sx={{ width: 80, height: 100, borderRadius: "0px" }}
                    image={image ?? defaultMiniature}
                    alt="digitized document"
                />
                <SoftButton
                    size={"small"}
                    variant="text"
                    style={{marginLeft: "0.5rem", textTransform: "none"}}
                    onClick={() => {
                        handleOpenPreview()
                    }}
                    color={"info"}
                >
                    <PiMagnifyingGlassPlusFill />
                    More info
                </SoftButton>
            </SoftBox>
            <SoftBox sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <SoftTypography component="div" variant="h6">
                        <SoftBox>
                            <Highlighter
                                searchWords={[searchText]}
                                textToHighlight={title}
                            />

                            {status != "COMPLETE" && status != "ERROR" &&
                                <SoftButton variant="gradient" color={"warning"} size={"small"}
                                            style={{marginLeft: "5px"}}
                                            disabled>
                                    Digitization in Progress
                                </SoftButton>
                           }
                            {status === "ERROR" &&
                                <SoftButton variant="gradient" color={"error"} size={"small"}
                                            style={{marginLeft: "5px"}}
                                            disabled>
                                    Digitization Failed
                                </SoftButton>
                            }
                        </SoftBox>
                    </SoftTypography>
                    <SoftTypography component="div" sx={{paddingTop: "10px", paddingLeft: "10px"}}>
                        {author &&
                            <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                <span> <strong>Author: </strong></span>
                                <Highlighter
                                    searchWords={[searchText]}
                                    textToHighlight={author}
                                />
                            </SoftTypography>
                        }
                        {publishedAt &&
                            <SoftTypography component="div" variant="body2" sx={{fontSize: "0.9rem"}}>
                                <span><strong>Published: </strong></span>
                                <Highlighter
                                    searchWords={[searchText]}
                                    textToHighlight={publishedAt}
                                />
                            </SoftTypography>
                        }
                        {summary && (
                            <SoftTypography component="div" variant="body2" sx={{ fontSize: "0.9rem" }}>
                                <span><strong>Summary: </strong></span>
                                <ReactReadMoreReadLess
                                    charLimit={400}
                                    readMoreText={"Read more ▼"}
                                    readLessText={"Read less ▲"}
                                >
                                    {summary}
                                </ReactReadMoreReadLess>
                            </SoftTypography>
                        )}
                    {subjects && subjects.length > 0 && (
                            <SoftTypography component="div" variant="body2" sx={{fontSize: "0.7rem", paddingTop: "8px"}}>
                                {subjects.map((subject, index) => (
                                    <SoftBox
                                        key={index}
                                        bgColor="dark"
                                        color="white"
                                        borderRadius="lg"
                                        sx={{
                                            display: "inline-block",
                                            padding: "5px 10px",
                                            margin: "5px",
                                            fontWeight:"lighter"
                                        }}
                                    >
                                        {subject}
                                    </SoftBox>
                                ))}
                            </SoftTypography>
                    )}
                    </SoftTypography>
                </CardContent>
            </SoftBox>
            <SoftBox sx={{position: "absolute", right: 0, marginRight: "10px"}}>
                {controller.role === 'Admin' && (
                    <Tooltip title="Delete article">
                        <IconButton aria-label="Delete article" color="info" onClick={handleOpenDeleteDialog}>
                            <MdDelete />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Download Bookmarking" arrow>
                    <IconButton aria-label="Download Bookmarking" disabled={status != "COMPLETE"} color="info" onClick={downloadBookmarkingFile}>
                        <CiBookmark />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download digitized article">
                    <IconButton aria-label="Download digitized article" disabled={status != "COMPLETE"} color="info" onClick={() => handleClickDownloadDigitizedDocument()}>
                        <TbTextScan2 />
                    </IconButton>
                </Tooltip>
            </SoftBox>
            {openDeleteDialog && (
                <DeleteArticleDialog isModalOpen={openDeleteDialog} handleCloseModal={handleCloseDeleteDialog} articleId={articleId} articleTitle={title} refreshArticles={refreshArticles}  setNotification={setNotification}/>
            )}
            <SoftBox sx={{position: "absolute", right: 0, marginRight: "140px", marginTop: "10px"}}>
                <SoftTypography fontWeight={"light"} style={{fontSize: "0.8rem"}}>{createdAt}</SoftTypography>
            </SoftBox>
        </Card>
    );
};

// Typechecking props for the BasicLayout
CardItem.propTypes = {
    title: PropTypes.string,
    summary: PropTypes.string,
    author: PropTypes.string,
    image: PropTypes.string,
    status: PropTypes.string,
    publishedAt: PropTypes.node,
    handleOpenPreview: PropTypes.func,
    createdAt: PropTypes.string,
    searchText: PropTypes.string,
    handleClickDownloadDigitizedDocument: PropTypes.func,
    downloadBookmarkingFile: PropTypes.func,
    subjects:PropTypes.array,
    setNotification: PropTypes.func,
    refreshArticles: PropTypes.func,
    articleId: PropTypes.number
};
export default CardItem;
