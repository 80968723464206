import {SET_PICTURE, SET_TOKEN, SET_USERINFO} from "../actions/app-actions";


export const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    sidenavColor: "primary",
    transparentNavbar: false,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    username: null,
    picture: null,
    token: null,
    role: null,
}

export function appReducer(state = initialState, action) {

    switch (action.type) {
        case "MINI_SIDENAV": {
            return { ...state, miniSidenav: action.value };
        }
        case "TRANSPARENT_SIDENAV": {
            return { ...state, transparentSidenav: action.value };
        }
        case "SIDENAV_COLOR": {
            return { ...state, sidenavColor: action.value };
        }
        case "TRANSPARENT_NAVBAR": {
            return { ...state, transparentNavbar: action.value };
        }
        case "FIXED_NAVBAR": {
            return { ...state, fixedNavbar: action.value };
        }
        case "OPEN_CONFIGURATOR": {
            return { ...state, openConfigurator: action.value };
        }
        case "DIRECTION": {
            return { ...state, direction: action.value };
        }
        case "LAYOUT": {
            return { ...state, layout: action.value };
        }
        case SET_USERINFO: {
            return { ...state, username: action.value.name, role: action.value.role};
        }
        case SET_PICTURE: {
            return { ...state, picture: action.value };
        }
        case SET_TOKEN: {
            return { ...state, token: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}