import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import { LiaUniversitySolid } from "react-icons/lia";
import { MdLocalPhone } from "react-icons/md";
import { HiMiniUsers } from "react-icons/hi2";
import { LuBuilding } from "react-icons/lu";
import SoftButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";
import { getUniversities, updateDepartment } from "../../axios-client";

const EditDepartmentDialog = ({ open, onClose, onDepartmentAdded, departmentData }) => {
    const [formData, setFormData] = useState({
        name: departmentData ? departmentData.name : '',
        university: departmentData ? departmentData.university.id : '',
        phone: departmentData ? departmentData.phone : '',
        max_users: departmentData ? departmentData.max_users : '',
    });
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [universities, setUniversities] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (open && departmentData) {
            setFormData({
                name: departmentData.name,
                university: departmentData.university.id,
                phone: departmentData.phone,
                max_users: departmentData.max_users,
            });
        }
    }, [open, departmentData]);

    useEffect(() => {
        async function loadUniversities() {
            try {
                const response = await getUniversities();
                setUniversities(response.data || []);
            } catch (error) {
                console.error("Failed to load universities", error);
                setUniversities([]);
            }
        }
        loadUniversities();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.university) formErrors.university = 'University is required';
        return formErrors;
    };

    const handleSubmit = async () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            try {
                await updateDepartment(departmentData.id, formData);
                setSnackbar({ open: true, message: 'Department edited successfully', severity: 'success' });
                onDepartmentAdded();
            } catch (error) {
                setSnackbar({ open: true, message: 'Failed to edit Department', severity: 'error' });
            }
        } else {
            setErrors(formErrors);
            setSnackbar({ open: true, message: 'Please fill out all required fields', severity: 'error' });
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Update Department Information
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8, size: 10 }}
                />
            </DialogTitle>
            <Divider />
            <DialogContent>
                <label htmlFor="name" className="label-style">Department name</label>
                <div style={{...inputContainerStyle}}>
                    <LuBuilding className="icon-style"/>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        style={inputStyle}
                        value={formData.name}
                        onChange={handleChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                </div>
                {errors.name && <p style={{color: 'red', fontSize: "12px"}}>{errors.name}</p>}

                <label htmlFor="university" className="label-style">University</label>
                <SoftBox mb={2} style={{position: 'relative'}}>
                    <LiaUniversitySolid className="icon-style" style={iconPositionStyle}/>
                    <select
                        id="university"
                        name="university"
                        value={formData.university}
                        onChange={handleChange}
                        style={selectStyle}
                    >
                        <option disabled={true} value="">
                            Select a university
                        </option>
                        {universities.map((university) => (
                            <option key={university.id} value={university.id}>
                                {university.name}
                            </option>
                        ))}
                    </select>
                    {errors.university && (
                        <SoftTypography color="error" fontSize="small">
                            {errors.university}
                        </SoftTypography>
                    )}
                </SoftBox>

                <label htmlFor="phone" className="label-style">Phone</label>
                <div style={inputContainerStyle}>
                    <MdLocalPhone className="icon-style"/>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        style={inputStyle}
                        value={formData.phone}
                        onChange={handleChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                </div>

                <label htmlFor="max_users" className="label-style">Max Users</label>
                <div style={inputContainerStyle}>
                    <HiMiniUsers className="icon-style"/>
                    <input
                        type="number"
                        id="max_users"
                        name="max_users"
                        style={inputStyle}
                        value={formData.max_users}
                        onChange={handleChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <SoftButton variant="outlined" color="dark" onClick={handleCancel}>
                    Cancel
                </SoftButton>
                <SoftButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Save
                </SoftButton>
            </DialogActions>

            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

const inputContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid #dcdfe3`,
    borderRadius: '8px',
    padding: '5px 10px',
    marginBottom: '10px',
    transition: 'border-color 0.3s ease',
};

const inputStyle = {
    width: '100%',
    padding: '10px 0px',
    border: 'none',
    fontSize: '14px',
    boxSizing: 'border-box',
    outline: 'none',
    fontFamily: 'Arial, Roboto',
};

const iconPositionStyle = {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    fontSize: '20px',
    color: '#666'
};

const selectStyle = {
    width: "100%",
    padding: "10px 10px 10px 40px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    background: "transparent",
};

EditDepartmentDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDepartmentAdded: PropTypes.func,
    departmentData: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        max_users: PropTypes.number,
        phone: PropTypes.string,
        university: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    }),
};

export default EditDepartmentDialog;
