import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import configs from "./configs/BarChartConfig";



function BarChart({chart, color= "dark"}) {
    const { data, options } = configs(chart.labels || [], chart.datasets || []);

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox padding="1rem">
                {useMemo(
                    () => (
                        <SoftBox
                            variant="gradient"
                            bgColor={color}
                            borderRadius="lg"
                            py={2}
                            pr={0.5}
                            mb={3}
                            height="12.5rem"
                        >
                            <Bar data={data} options={options} />
                        </SoftBox>
                    ),
                    [chart, color]
                )}
            </SoftBox>
        </Card>
    );
}




BarChart.propTypes = {
    chart: PropTypes.object,
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "#FFF"]),
};

export default BarChart;
