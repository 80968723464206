import { Link} from "react-router-dom";
import SoftTypography from "components/SoftTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import digitization from "assets/images/curved-images/registration.png";

function Confirmation() {
    return (
        <BasicLayout
            title="Registration complete!"
            description="Thanks for signing up for Yai4Edu, we are happy to have you. Now, your request is waiting to be approved"
            image={digitization}
        >
            <div style={{textAlign: 'center', marginTop: '20px'}}>
                <SoftTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="primary"
                    fontWeight="bold"
                    textGradient
                >
                    Back To Login Page
                </SoftTypography>
            </div>
        </BasicLayout>
    );
}

export default Confirmation;
