import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import {addDepartment, getDepartementsList} from "../../axios-client";
import AddDepartmentDialog from "./AddDepartmentDialog";
import { IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import EditDepartmentDialog from "./EditDepartmentDialog";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import {formatDataToUrlQueryParams} from "../../utlis/generalUtils";

const Departments = (props) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddDepartmentDialog, setOpenAddDepartmentDialog] = useState(false);
    const [openEditDepartmentDialog, setOpenEditDepartmentDialog] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})
    const [departmentsCount, setDepartmentsCount] = useState(0);

    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
    });

    const fetchData = (filterData) => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        getDepartementsList(`?${queryParams}`)
            .then(res => {
                const formattedRows = res.data.departments.map(department => ({
                    "Department Name": department.name,
                    "University": department.university.name,
                    "Max Users": department.max_users,
                    "Phone": department.phone,
                    "Action":  (
                        <IconButton
                            onClick={() => handleOpenEditDialog(department)}
                            style={{ cursor: 'pointer' }}
                            size="small"
                        >
                            <Edit />
                        </IconButton>
                    ),
                }));

                setDepartmentsCount(res.data.count)
                setRows(formattedRows);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching departments:', error);
                setLoading(false);
            });
    }
    const handleOpenEditDialog = (department) => {
        setSelectedDepartment(department);
        setOpenEditDepartmentDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDepartmentDialog(false);
        setSelectedDepartment(null);
    };

    useEffect(() => {
        if(filterData) {
            fetchData(filterData);
        }
    }, [filterData]);

    const handleAddDepartment = async (formData) => {
        const { department, university, phone, maxUsers } = formData;

        const data = {
            name: department,
            university_id: university,
            phone,
            max_users: maxUsers
        };

        try {
            await addDepartment(data);
            fetchData(filterData);
            setOpenAddDepartmentDialog(false);
            setNotification({ show: true, snackbarMessage: 'Department added successfully!', severity: "success" });
        } catch (error) {
            console.error('Error adding department:', error);
            setNotification({ show: true, snackbarMessage: 'Failed to add department. Please try again!', severity: "error" });
        }
    };

    const handleOpenDialog = () => {
        setOpenAddDepartmentDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddDepartmentDialog(false);
    };

    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({ ...prev, page: newPage }));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
    };


    return (
        <>
            <Tables rows={rows} title={"Departments List"} showAddButton={true} ButtonName={"Add department"}
                    onButtonClick={handleOpenDialog} loadingData={loading}  size={filterData.size}
                    page={filterData.page}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    count={departmentsCount} />
            {openAddDepartmentDialog && (
                <AddDepartmentDialog
                    open={openAddDepartmentDialog}
                    onClose={handleCloseDialog}
                    handleAddDepartment={handleAddDepartment}
                />
            )}
            {openEditDepartmentDialog && (
                <EditDepartmentDialog
                    open={openEditDepartmentDialog}
                    onClose={handleCloseEditDialog}
                    onDepartmentAdded={fetchData}
                    departmentData={selectedDepartment}
                />
            )}


            <SnackbarNotification
                   notification={notification}
                   setNotification={setNotification}
               />
        </>
    );

}
export default Departments;