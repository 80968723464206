import {Document, Packer, Paragraph, TextRun} from "docx";

/**
 * This function takes a date string as an argument, creates a new Date object from it,
 * and then uses the toLocaleDateString() method to format the date according to the
 * specified options
 * @param dateString
 * @returns {string}
 */
export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}


/**
 * Returns the current date in the 'YYYY-MM-DD' format based on the local timezone.
 * @returns {string} Formatted date (YYYY-MM-DD)
 */
export const getFormattedTodayDate = () => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
};

export function downloadFile(content, fileName, contentType) {

    const a = document.createElement('a');
    const file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

export const downloadFileFromUrl = (fileName, url) => {

    fetch(url).then((response) => {
        response.blob().then((blob) => {

            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        });
    });
};

export const downloadDocFile = async (fileName, paragraphs) => {

    const childrenParagraphs = paragraphs
        .filter(paragraph => paragraph.trim() !== "")
        .map((paragraph) => {
            const [header, ...rest] = paragraph.split('\n');
            const bodyText = rest.join('\n\n').trim();

            return new Paragraph({
                children: [
                    new TextRun({
                        text:  "\n" + header,
                        color: "0000FF",
                        bold: true,
                    }),
                    new TextRun({
                        text: bodyText,
                        break: 1,
                    }),
                ],
                spacing: {
                    after: 200,
                },
            });
        });

    const doc = new Document({
        sections: [
            {
                properties: {},
                children: childrenParagraphs
            }
        ]
    })

    Packer.toBlob(doc).then((blob) => {
        const a = document.createElement('a');

        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    })
}

// Function to format data to URL query parameters
export const formatDataToUrlQueryParams = (data) => {
    const params = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        if (value) {
            params.append(key, value);
        }
    });
    return params.toString();
};
